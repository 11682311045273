import { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Breadcrumbs, Button, Grid, Tooltip, Typography } from 'maple-storybook'
import { Icon, KeyboardArrowRight, Notifications, unGroup } from 'maple-storybook/assets'
import moment from 'moment'
import NotificationPanel from 'views/NotificationPanel'

import {
  createAttendanceFailure,
  createAttendanceSuccess,
  fetchAttendanceFailure,
  fetchAttendanceSuccess
} from 'actions/ams'
import { fetchNotificationsFailure, fetchNotificationsSuccess, setCallInProgress } from 'actions/notifications'
import { createAttendance, fetchAttendance } from 'api/ams'
import { fetchNotifications } from 'api/notifications'
import notificationIcon from 'assets/img/sidebarIcons/notifications.svg'
import ItemGrid from 'components/Grid/ItemGrid'
import { breadCrumbsTab, checkArrayEmpty, tabHeading } from 'helpers/applicationHelper'
import { NOTIFICATOINS_PAGE_SIZE } from 'helpers/constants'
import { checkPermission } from 'helpers/permissionsHelper'

import Profile from './Profile'

import './styles.scss'

const PAGE_NUMBER = 1
const notificationsupported = 'Notification' in window

const showDesktopNotification = (notificationObj, callback) => {
  const notification = new Notification('New message incoming', { body: notificationObj.message })
  notification.onclick = () => callback(notificationObj)
}

const HeaderLinks = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const notifications = useSelector((state) => state.notifications.allNotifications)
  const link = props.company?.company_policy?.link
  const correctedLink = link && !link.startsWith('http://') && !link.startsWith('https://') ? 'https://' + link : link
  const isCheckinDenied =
    checkPermission(props.userPermissions, 'ams', 'general', 'mark_attendance').isNone() ||
    !props.company.modules?.ams ||
    isEmpty(props.profile.ams_active_shift)
  const isCheckedIn = !isEmpty(props.attendance) && moment(props.attendance?.check_in_time).isSame(moment(), 'day')
  const isCheckInDisabled = isCheckedIn || isCheckinDenied
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const checkNotificationPermission = () => {
    if (!notificationsupported) return

    if (Notification.permission !== 'granted') {
      if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(() => {
          showDesktopNotification({ message: 'Desktop Notifications enabled for MapleHR!' }, () => {})
        })
      }
    }
  }

  useEffect(() => {
    checkNotificationPermission()
    !isCheckInDisabled &&
      dispatch(
        fetchAttendance(
          { user_id: props.currentUserId, date: new Date().toLocaleDateString('en-CA') },
          fetchAttendanceSuccess,
          fetchAttendanceFailure
        )
      )
    checkArrayEmpty(notifications.notifications) &&
      dispatch(
        fetchNotifications(
          PAGE_NUMBER,
          NOTIFICATOINS_PAGE_SIZE,
          false,
          false,
          fetchNotificationsSuccess,
          fetchNotificationsFailure
        )
      )
  }, [])

  const handleNotificationsClick = () => {
    checkNotificationPermission()
    if (notifications.notifications?.length === 0) {
      dispatch(setCallInProgress('loadMoreNotifications'))
      dispatch(
        fetchNotifications(
          PAGE_NUMBER,
          NOTIFICATOINS_PAGE_SIZE,
          false,
          false,
          fetchNotificationsSuccess,
          fetchNotificationsFailure
        )
      )
    }
    setDrawerOpen(true)
  }

  const handleCheckInClick = () => {
    dispatch(createAttendance(createAttendanceSuccess, createAttendanceFailure))
  }

  const noAccess = checkPermission(props.userPermissions, 'faqs', 'general', 'faqs_panel').isNone()

  return (
    <Grid container className="d-flex flex-row">
      <ItemGrid itemGridClasses={`d-flex tabs-heading-container`} xs={12} sm={12} md={7} lg={8}>
        <div className={`flex-column heading-container ${props.sidebarOpen && 'sidebar-open-header'}`}>
          {breadCrumbsTab(location.pathname, props).length > 0 && (
            <ItemGrid container itemGridClasses="align-items-center pd-0 mt-half bread-crumbs-grid">
              <Breadcrumbs separator={<KeyboardArrowRight fontSize="small" />}>
                {breadCrumbsTab(location.pathname, props).map((label, index) => (
                  <Typography
                    key={index}
                    onClick={() => label[1] && navigate(label[1])}
                    clickable
                    variant="text-sm"
                    color={index === breadCrumbsTab(location.pathname, props).length - 1 ? 'primary-600' : 'gray-500'}>
                    {label[0]}
                  </Typography>
                ))}
              </Breadcrumbs>
            </ItemGrid>
          )}
          {tabHeading(location.pathname, props.profile)['title'] && (
            <Typography type="semibold" variant="display-xs" color="gray-900" className="ml-half mt-half mb-half">
              {tabHeading(location.pathname, props.profile)['title']}
            </Typography>
          )}
          {tabHeading(location.pathname, props.profile)['subtitle'] && (
            <Typography type="normal" variant="text-sm" color="gray-500" className="ml-half mb-1">
              {tabHeading(location.pathname, props.profile)['subtitle']}
            </Typography>
          )}
        </div>
      </ItemGrid>
      <ItemGrid xs={12} sm={12} md={5} lg={4} container itemGridClasses="header-links-main-div">
        <Profile />
        {props.profile?.fields_fill && (
          <>
            {notifications?.unread_count > 0 ? (
              <img
                src={notificationIcon}
                className="header-notification-icon"
                alt={'icon'}
                onClick={handleNotificationsClick}
              />
            ) : (
              <Notifications
                className="header-notification-icon header-no-notification-icon cursor-pointer"
                onClick={handleNotificationsClick}
              />
            )}
          </>
        )}
        {!noAccess && props.company?.company_policy?.link && (
          <Link to={correctedLink} target="_blank" className="navbar-policy-container">
            <Tooltip title={props.company?.company_policy?.name} className="d-flex align-items-end mr-2">
              <Icon className="navbar-policy-icon" paths={unGroup} fillColor="gray-500" size={24} />
              <Typography variant="text-sm" type="medium" color="gray-500" sx={{ lineHeight: '32px' }}>
                Policies & Procedures
              </Typography>
            </Tooltip>
          </Link>
        )}
        {!isCheckinDenied && (
          <Button
            label={isCheckedIn ? 'Checked In!' : 'Check In'}
            className="header-check-in-btn"
            disabled={isCheckInDisabled}
            variant="primary"
            onClick={handleCheckInClick}
          />
        )}
        <NotificationPanel drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      </ItemGrid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  const currentUserId = state.reduxTokenAuth.currentUser.attributes.id

  return {
    currentUserId,
    userPermissions: state.permissions.userPermissions,
    profile: state.users.allUserProfiles[currentUserId],
    company: state.currentCompany,
    supportStaff: state.users.allUserProfiles[state.reduxTokenAuth.currentUser.attributes.id]?.support_staff,
    attendance: state.ams.attendance,
    sidebarOpen: state.sidebar.open,
    breadCrumbs: state.breadCrumbs
  }
}

export default connect(mapStateToProps, null)(HeaderLinks)
